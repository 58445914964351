.slick-slider {
    
}

.slick-list {
    padding: 0 16px;
    margin: 0px 80px;
    overflow: hidden;
}

.slick-slider .slick-prev {
    width: 16px;
    position: absolute;
    top: 40%;
    background-color: transparent;
    border: transparent;
    font-size: 0px;
    &:focus {
        outline: none;
    }
}

.slick-slider .slick-next {
    width: 16px;
    position: absolute;
    top: 40%;
    right: 2%;
    background-color: transparent;
    border: transparent;
    font-size: 0px;
    &:focus {
        outline: none;
    }
}

.slick-slide div { outline: none; }

.slick-prev:before {
    content: url('/images/icons/previousArrow.png');
}

.slick-next:before {
    content: url('/images/icons/nextArrow.png');
}

.slick-track {
    display: flex
}

.slick-dots {
    bottom: 0;
    position: relative;
    margin-top: 0.8rem;
    display: flex !important;

    & > li {
      background: #f7f7f7;
      border-radius: 50%;
      width: 15px;
      height: 15px;

      &.slick-active {
        background-color: teal;
      }

      & > button {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        border: none;

        &:before {
          content: none;
        }
      }
    }
}

@media only screen and (max-width: 800px) {
    .slick-list {
        padding: 0 16px;
        margin: 0px 40px;
        overflow: hidden;
    }
}