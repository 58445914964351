* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'Bebas Neue';
    font-weight: 300;
    src: local('Bebas Neue'), url('../font/Bebas_Neue/BebasNeueLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Bebas Neue';
    font-weight: 'bold';
    src: local('Bebas Neue'), url('../font/Bebas_Neue/BebasNeueBold.woff') format('woff');
  }

  @font-face {
    font-family: 'Bebas Neue';
    font-weight: 'normal';
    src: local('Bebas Neue'), url('../font/Bebas_Neue/BebasNeueRegular.woff') format('woff');
  }

  body {
    background-color: #f4f6f8;
    height: 100%;
    font-family: "PT Sans Caption"
  }
  
  a {
    text-decoration: none;
  }
  
  #root {
    height: 100%;
  }
  
  $aos-distance: 50px;
  